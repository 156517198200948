import { useState } from "react";
import "../index.css";
import { IconContext } from "react-icons";
import {FaBars, FaXmark} from "react-icons/fa6";
import Link from './Link';

function Menu() {

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    let slug = sessionStorage.slug;

    const handleClick = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    const content = !isMenuVisible? 
                    <IconContext.Provider value={{ color: "black" }}>
                        <div>
                            <FaBars size={window.innerWidth < 600? 30: 45}/> 
                        </div>
                    </IconContext.Provider>: 
                    <IconContext.Provider value={{ color: "white" }}>
                        <div>
                            <FaXmark size={window.innerWidth < 600? 30: 45}/> 
                        </div>
                    </IconContext.Provider>;
    
    const links = [
        { label: 'Course', path: '/course?' + slug },
        { label: 'Artworks catalog', path: '/artworks-catalog' },
        { label: 'Flashcards', path: '/flashcards' }
      ];
    
    const renderedLinks = links.map((link) => {
            return (
            <Link
                key={link.label}
                to={link.path}
                onClick={handleClick}
            >
                {link.label}
            </Link>
            );
    });

        const content2 = (<div className="visibleMenuContainer">
            {renderedLinks}
        </div>);

    return <div >
                <div className="toggled-menu-container" onClick={handleClick}>
                    {content}
                </div>
                    {isMenuVisible && content2}
            </div>
}

export default Menu;
