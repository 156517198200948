import "./index.css";
import Route from "./components/Route";
import FlashcardPage from "./pages/FlashcardPage";
import ArtworkPage from "./pages/ArtworkPage";
import HomePage from "./pages/HomePage";
import CourseMainPage from "./pages/CourseMainPage";
import GeneralMenu from "./components/GeneralMenu";
import LoginSignupPage from "./pages/LoginSignupPage";
import ArtworkCatalogsPage from "./pages/ArtworksCatalogPage";

function App() {

    return <div>
      
      <Route path="/artwork">
        <GeneralMenu />
        <ArtworkPage />
      </Route>
      <Route path="/artworks-catalog">
        <GeneralMenu />
        <ArtworkCatalogsPage />
      </Route>
      <Route path="/flashcards">
        <GeneralMenu />
        <FlashcardPage />
      </Route>
      <Route path="/">
          <HomePage />
      </Route>
      <Route path="/course">
        <GeneralMenu />
        <CourseMainPage />
      </Route>
      <Route path="/login">
        <GeneralMenu />
        <LoginSignupPage />
      </Route>

      
      
       
    </div>
        
}

export default App;