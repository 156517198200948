import { useState } from "react";

function ArtworksCatalogCard ({image, course}) {

    const [showHover, setShowOver] = useState(false);

    const handleHover = () => {
        setShowOver(true);
    }

    const handleLeave = () => {
        setShowOver(false);
    }

    const handleClick = () => {
        window.location.href = window.location.origin + `/artwork?id=${image.id}&course=${course}`;
    }

    return <div className="image-template template" onMouseOver={handleHover} onMouseLeave={handleLeave}
                onClick={handleClick}
                style={{backgroundImage: `url(${image.image_url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"}}>
                {showHover && <div className="artwork-catalog-card-overlay">
                    <p>{image.title}</p>
                </div> }
            </div>


}

export default ArtworksCatalogCard;