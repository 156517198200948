import CourseList from "../components/CourseList";
import GeneralMenu from "../components/GeneralMenu";
import LoginSignupButtons from "../components/LoginSignupButtons";

function HomePage() {

    return <div>
            { localStorage.IYFLClxBz4IE3rn6OwU0tef1fm4YVcBS && 
                <div>
                    <GeneralMenu />
                    <CourseList /> 
                </div>
                }
            { !localStorage.IYFLClxBz4IE3rn6OwU0tef1fm4YVcBS && 
                    <LoginSignupButtons />     
            }        
        </div>

}

export default HomePage;