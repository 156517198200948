import axios from "axios";
import { IconContext } from "react-icons";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { decypherToken } from "../utils";
import {api_url} from "../constants";

function Course({course, onClick}) {


    const handleClick = async () => {
        
        if (!course.enrolled) {

            let token = await decypherToken(true);

            let data = {
                course_id: course.id
            }

            const response = await axios({
                method: 'post',
                url: api_url + "user-enroll",
                headers: {"Content-Type": "application/json", "Authorization": "Bearer " + token}, 
                data: data
            });
    
            if(response.status == 200){
    
                window.location.href = window.location.pathname + "course?" + course.slug;
                return;
            }
        }

        window.location.href = window.location.pathname + "course?" + course.slug;

    }

    let styling = {
        text: course.enrolled? "Continue learning": "Enroll",
        style: {
            background: course.enrolled? "#005467" : "#344f15"
        }
       
    }

    if (course.completed == 1 ) {
        styling = {
            text: "Course completed!",
            style: {
                borderTop: "1px solid white",
                borderBottom: "1px solid white",
                backgroundColor: "rgba(0,0,0,0.5)"
            } 
        }
    } 

    course.cover_img = 
        course.cover_img && course.cover_img.includes("http")? 
        course.cover_img:
        api_url + "assets/" +  course.cover_img;

    return <div className="course-div" style={{
                    backgroundImage: 
                    `linear-gradient(45deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${course.cover_img})`,
                    backgroundSize: "cover"
                }}>
                <div className="info-icon" onClick={onClick}>
                    <IconContext.Provider value={{ color: "white" }} >
                        <div>
                            <BsFillInfoCircleFill size={20}/> 
                        </div>
                    </IconContext.Provider>
                </div>
                <h4>{course.title}</h4>
                <span>{course.author}</span>
                {course.subtitle}
                <div className="enroll-continue-banner" 
                    style={styling.style}
                    onClick={handleClick}>{styling.text}
                </div>
            </div>

}

export default Course;