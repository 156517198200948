import { useState, useEffect } from "react";
import { api_url } from "../constants";


function Flashcard({artwork, onClick, showNext}) {

    const awData = artwork;
    const [isFlipped, setIsFlipped] = useState(showNext);

    const handleClick = () => {
        setIsFlipped(true);
        onClick();
    }

    useEffect(()=>{
        if (!showNext){
            setIsFlipped(false);
        }
    }, [showNext])

    let hidden = {display: "none"};

    awData.image_url = 
    awData.image_url.includes("http")? 
    awData.image_url:
    api_url + "assets/" + awData.image_url

    return <div className="flashcard-container">
            <div className={`flashcard `} >
                <div className="flashcard-front">
                    <img src={awData.image_url} />
                </div>
                <div className="flashcard-back" style={isFlipped? {}: hidden}>
                    <p>{awData.author}</p>
                    <h3>{awData.title}</h3>
                    <p>{awData.date}</p>
                    <p>{awData.period}</p>
                    <p>{awData.media}</p>
                    <p>{awData.size}</p>
                </div>
                <button className="flashcard-button" onClick={handleClick}  style={isFlipped? hidden: {}}>Show</button>
            </div>
        </div>
    
        
}

export default Flashcard;