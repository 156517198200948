import { useState, useEffect } from "react";
import axios from "axios";
import "../index.css";
import Menu from "../components/Menu";
import { decypherToken } from "../utils";
import { api_url } from "../constants";

function CourseMainPage({})
{

    const [sections, setSections] = useState([]);
    const [course, setCourse] = useState({}); 

    const getSections = async (courseSlug) => {

        let options = await decypherToken();

        const response = await axios.get(api_url + `course-sections?slug=${courseSlug}`, options);
        setSections(response.data.sections);
        setCourse(response.data.course);
    }

    useEffect( () => {
        let slug = window.location.search.split("?")[1];
        getSections(slug);
        sessionStorage.setItem("slug", slug);
    }, []);

    let firstNotCompleted = true;
    let nextToComplete = 0;
    const renderedSections = sections.map( (section, index) => {

        let className = "";
        let styling = {};
        let span;
        if (!section.completed) {
            if (firstNotCompleted) {
                firstNotCompleted = false;  
                nextToComplete = section.id;
                styling = {fontWeight: "bold"}
                span = <span className="next-class-span">Next class!</span>
            } else {
                className ="blurred-text" ;
                styling = {pointerEvents: "none"};
                span = "";
            }
                
        }

        const handleSectionClick = (artworkId) => {
            window.location.href = window.location.origin + `/artwork?id=${artworkId}&course=${course.id}`; 
        }

        return <div key={section.id} className="course-section" style={styling} >
                    <p onClick={() => handleSectionClick(section.id)} className={className}>{(index+1) + ") " + section.title}{span}</p>
                </div>
    });

    const handleButtonClick = () => {

        window.location.href = window.location.origin + `/artwork?id=${nextToComplete}&course=${course.id}`; 

    }

    return <div className="course-page">
        <Menu slug={course.slug}/>
        <h1>{course.title}</h1>
        <div className="sections-container">{renderedSections}</div>
        { !course.completed && <div className="continue-course-button"><button onClick={handleButtonClick}>Continue this course</button></div>}
        { course.completed && <div className="continue-course-button"
            style={{color: "rgb(0, 84, 103)", fontWeight: "bold"}}>Congratulations! You have completed the course</div>}
    </div>
}

export default CourseMainPage; 