import { useState, useEffect } from "react";
import TextInput from "../components/TextInput";
import axios from "axios";
import { randomString } from "../utils";
import {api_url} from "../constants";

function LoginSignupPage() {

    const [signUpShow, setSignUpShow] = useState(window.location.search.split("=")[1] === "reg");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [disabledButton, setDisabledButton] = useState(true);
    const [errorMessageVisible, setErrorMessageVisible] = useState("hidden");
    
    const loginForm = [{label: "Email", name:"email", password:false}, {label: "Password", name:"password", password:true}];
    const signUpForm = [{label: "Name", name:"name", password:false}, ...loginForm, {label: "Confirm password", name:"confirm-password", password:true}];

    const handleChange = (value, input) => {

        let emailInput = email;
        let passwordInput = password;
        let nameInput = name;
        let confirmPasswordInput = confirmPassword;
        
        switch(input) {
            case "email":
                setEmail(value);
                emailInput = value;
                break;
            case "password":
                setPassword(value);  
                passwordInput = value; 
                break;
            case "name":
                setName(value);   
                nameInput = value;
                break;
            case "confirm-password":
                setConfirmPassword(value);   
                confirmPasswordInput = value;
                break;
        }

        let nameOK = false;
        let passwordConfirmed = false;

        if (!signUpShow || Boolean(nameInput)) nameOK = true;
        if (!signUpShow || (passwordInput == confirmPasswordInput)) passwordConfirmed = true;

        if (emailInput && passwordInput && nameOK && passwordConfirmed) {
            setDisabledButton(false);
        }

        if (!emailInput || !passwordInput || !nameOK || !passwordConfirmed) {
            setDisabledButton(true);
        }
        
    }

    const renderLoginForm = loginForm.map((input) => {
        return <TextInput onChange={handleChange} key={input.name} label={input.label} name={input.name} password={input.password} />
    });

    const renderSignupForm = signUpForm.map((input) => {
        return <TextInput onChange={handleChange} key={input.name} label={input.label} name={input.name} password={input.password}/>
    });

    
    const handleSubmit = async (event) => {
    
        event.preventDefault();
        
        let email =  event.target.email.value;
        let password =  event.target.password.value;
        let name = signUpShow? event.target.name.value: "";

        let data = {
           email,
           password,
           name
        }

        let endpoint = api_url;
        endpoint += !signUpShow? "login": "sign-up";

        let response = null;

        try {
            response = await axios({
                method: 'post',
                url: endpoint,
                headers: {"Content-Type": "application/json"}, 
                data: data
            });
        } catch(err) {
            setErrorMessageVisible("visible");
        }


        if(response.status == 200){
            localStorage.setItem("IYFLClxBz4IE3rn6OwU0tef1fm4YVcBS",  response.data.cookie);
            localStorage.setItem("Wz97nSt6HBaCM2jtemoBJnbE8i4wFiwF", randomString(320));
            localStorage.setItem("qpvXSYg225kvejx5Vu5hY21aDs7omiMR",  randomString(322));
            window.location.href = window.location.origin;
        } else {
            alert("Wrong username or password!");
        }

    }

    return <div className="container">
        <div className="form-container">
            <p style={{color: "red", textAlign: "center", visibility: errorMessageVisible}}>Wrong username or password</p>
            <form onSubmit={handleSubmit} autoComplete="off">
            {
                !signUpShow && 
                <div>
                    <div className="text-input-container">
                        {renderLoginForm}
                    </div>
                    <button className={`button-general ${disabledButton? "disabled-button": "" }`} >
                        Login
                    </button>
                </div>
            }
            {
                signUpShow && 
                <div>
                    <div className="text-input-container">
                        {renderSignupForm}
                    </div>
                    <button className={`button-general button-variation ${disabledButton? "disabled-button": ""}`} >
                        Sign up
                    </button>
                </div>
            }
            
            </form>
        </div>
    </div>
}

export default LoginSignupPage;