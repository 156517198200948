import axios from "axios";
import { useState, useEffect } from "react";
import Course from "./Course";
import Modal from "./Modal";
import { decypherToken } from "../utils";
import { api_url } from "../constants";

function CourseList() {

    const [courses, setCourses] = useState([]);
    const [showModal, setShowModal ] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState({});

    const handleClose = () => {
        setShowModal(false);
    }

    const handleClick = (course) => {
        setSelectedCourse(course);
        setShowModal(true);
    }

    const fetchCoursesData = async () => {

        const options = await decypherToken();

        const response = await axios.get(api_url + 'courses',
        options);

        setCourses(response.data);
        
    }

    useEffect(() => {
       fetchCoursesData();
    }, []);
   
    const renderedCourses = courses.map((course) => {
        return <Course key={course.id} course={course} onClick={() => handleClick(course)}></Course>;
    });

    return <div className="course-list">
        {renderedCourses}
        {showModal && <Modal onClose={handleClose} course={selectedCourse}/>}
    </div>
}

export default CourseList;

