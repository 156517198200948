import { useState } from "react";

function Tag({label, id, onChange}) {

    const [selected, setSelected] = useState(false);

    const handleTagChange = () => {
        onChange(id, !selected);
        setSelected(!selected);
    }

    return  <div key={id} 
                className={`tag-div ${selected? "selected" : "unselected"}`}
                onClick={handleTagChange}>
                    {label}
            </div>

}

export default Tag;