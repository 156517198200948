import Button from "./Button";

function LoginSignup() {


    const handleClick = (name) => {
 
        if (name == "login") {
            
            window.location.href = "./login";
          
        } else if (name == "signup") {
          
            window.location.href = "./login?p=reg";
           
        }
            
    }

    return <div className="container sign-up-page">
            <div>
                    <h1>Art History Courses</h1>
                    <h2><i>Your companion to art history</i></h2>
                    <Button onClick={handleClick} name="login" >
                        Login
                    </Button>
                    <Button variation={true} onClick={handleClick} name="signup">
                        Sign up
                    </Button>
                </div>  
           </div>
           

}

export default LoginSignup;