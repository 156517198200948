function Button({children, variation, onClick, name}) {


    let className = "button-general";
    className += variation? " button-variation": ""; 

    const handleClick = (event) => {

        event.preventDefault();

        onClick(name);

    }

    return <button onClick={handleClick} className={className}>{children}</button>

}

export default Button;