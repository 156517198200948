//const api_url = "http://localhost:3001/";
const api_url = "https://art-history-courses-server.onrender.com/";
//const backend_url = "http://localhost:8080";
const backend_url = "https://art-history-courses-instructors.onrender.com";
//const home_url  = "http://localhost:3000";
const home_url  = "https://art-history-courses.onrender.com";
//const iframe_link = "http://localhost:8080/zoom-img.html?img=";
const iframe_link = "https://art-history-courses-instructors.onrender.com/zoom-img.html?img=";
//const assets_url = "http://localhost:3001/assets/";
const assets_url = "https://art-history-courses-server.onrender.com/assets/";
export {api_url, backend_url, home_url, iframe_link, assets_url};