function VisualAnalysisQuestions () {

    return <div>
                <div>
                    Focus on SUBJECT MATTER. Try answering these questions:
                    <ul>
                        <li>
                            Is the image abstract or figurative?
                        </li>
                        <li>
                            What is the subject matter of this artwork?
                        </li>
                        <li>
                            Are there human figures? If so, what is their relationship with 
                            the overall composition? If not, is this absence significant?
                        </li>
                    </ul>
                </div>
                <div>
                    Focus on COMPOSITION and SPACE. Try answering these questions:
                    <ul>
                        <li>
                            As a viewer, from what position do I see the scene?
                        </li>
                        <li>
                            Can space be broken down into different planes 
                            (foreground, middle ground, and background)?
                        </li>
                        <li>
                            Does the space seem empty or busy? Is there any negative space? If so,
                            how does it contribute to the overall feeling?
                        </li>
                        <li>
                            Do the different elements appear to be connected to each other, or they just
                            seem juxtaposed? Are there diagonals?
                        </li>
                        <li>
                            In your opinion, the painter wants the eye of the viewer to roam freely on the
                            painting or the vision to be guided by certain compositional elements? If the
                            latter, what are these compositional elements and where do they lead your
                            gaze?
                        </li>
                        <li>
                            Is there great attention to detail?
                        </li>
                        <li>
                            Do we get a feeling or drama and movement or quietude and stillness?
                        </li>
                    </ul>
                </div>
            </div>

}

export default VisualAnalysisQuestions;