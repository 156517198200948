import useNavigation from '../hooks/use-navigation';

function Link({ to, children, onClick }) {
  const { navigate } = useNavigation();

  const handleClick = (event) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }
    event.preventDefault();

    navigate(to);
    onClick();
  };

  return (
    <h2 href={to} onClick={handleClick}>
      {children}
    </h2>
  );
}

export default Link;
