import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {FaTimes} from "react-icons/fa";

function SkipTimerModal( {onClose, onClick} ){

    useEffect(() => {

        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        }
    }, []);



    return ReactDOM.createPortal(
    <div>
        <div onClick={onClose} className="modal-bg"></div>
        <div className="modal skip-timer-modal">
            <div className="close-modal" onClick={onClose}>
                <FaTimes />
            </div>
            <p>Are you sure you want to proceed to the artwork's explanation? There is so much more to look at!</p>
            <button onClick={onClick}>Yes, I am sure</button>
        </div>
    </div>,
    document.querySelector(".modal-container"));
} 

export default SkipTimerModal;