import { useState, useEffect } from "react";
import { iframe_link, assets_url } from "../constants";

function ArtworkExplanation({awData, awExtraImages, nextAw, courseId, tags}) {

    const [mainImg, setMainImg] = useState(awData.image_url);
    const [extraImages, setExtraImages] = useState(awExtraImages);
    const [nextArtwork, setNextArtwork] = useState(nextAw);

    useEffect(() => {
    setMainImg(awData.image_url)
    }, [awData.image_url]);

    useEffect(() => {
        setExtraImages(awExtraImages)
    }, [awExtraImages]);

    useEffect(() => {
        setNextArtwork(nextAw)
    }, [nextAw]);

    const changeMainImg = (imageUrl) => {

        let newExtraImages = extraImages;

        newExtraImages.map((extraImage, index) => {
            if (extraImage.rel_path == imageUrl) {
                newExtraImages.splice(index, 1);
            }
        })
        
        setExtraImages([...newExtraImages, {rel_path: mainImg.replace(assets_url, "")}]);

        setMainImg(imageUrl);
       
    }

    const periodTags = tags.map((tag)=>{
        if (tag.tag_type == "period")
            return <span className="period-span" key={tag.id}>{tag.tag}</span>
    });
    
    const renderExtraImages = extraImages.map((image)=>{

        return <div className="extra-img" key={image.rel_path} onClick={() => changeMainImg(image.rel_path) } data-code={image.rel_path}>
            <img src={assets_url + image.rel_path}/>
        </div>
    });

 

    const handleNextArtworkClick = () => {
        window.location.href = window.location.origin + `/artwork?id=${nextArtwork}&course=${courseId}`;
    }

    return  <div className="explanation-page-container">
                <div className="main-img-container">   
                
                    <div className="aw-appreciation-container" >

                        <iframe className="isss_iframe" src={iframe_link + mainImg} allowFullScreen>
                        </iframe>
                        
                        { Boolean(extraImages.length) &&
                        <div className="extra-images-container" >
                            {renderExtraImages}
                        </div>
                        }
                    </div>
                
                    <div className="caption-container">
                        {awData.author && <p>{awData.author}</p> }
                        {awData.title && <p ><strong>{awData.title}</strong></p> }
                        {awData.date && <p >{awData.date}</p> }
                        {periodTags && <p >{periodTags}</p> }
                        {awData.media && <p >{awData.media}</p> }
                        {awData.size && <p >{awData.size}</p> }
                        {awData.collocation && <p >{awData.collocation}</p> }
                    </div>    
                </div>
                <div className="aw-explanation">
                    <p>
                    {awData.description}
                    </p>
                    {Boolean(awData.youtube_link) && 
                    <iframe className="youtube_iframe"
                        width="560" height="315" 
                        src={awData.youtube_link} 
                        title="YouTube video player" frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen>
                    </iframe>
                    }
                </div>
                { Boolean(nextArtwork) &&
                <div className="timer-button-container">
                    <button onClick={handleNextArtworkClick}>Next artwork</button>
                </div>
                }
            </div>

}

export default ArtworkExplanation;