import Flashcard from "../components/Flashcard";
import axios from "axios";
import { useEffect, useState } from "react";
import { ImArrowRight } from "react-icons/im";
import Menu from "../components/Menu";
import { decypherToken } from "../utils";
import { api_url } from "../constants";

function FlashcardPage() {

    const [index, setIndex ] = useState(0);
    const [images, setImages] = useState([]); 
    const [showNext, setShowNext] = useState(false);

    const getSections = async (courseSlug) => {
        const options = await decypherToken();
        const data = {
            slug: courseSlug,
            order: 'random'
        }
        const response = await axios.post(api_url + `artworks/studied`, data, options);
        setImages(response.data.artworks);   
    }

    useEffect( () => {
        let slug = sessionStorage.getItem("slug");//window.location.search.split("?")[1];
        getSections(slug);
    }, []);

    const handleClick = () => {
        if (index < images.length - 1 ) {
            let newIndex = index+1;
            setIndex(newIndex);
            setShowNext(false);
        }
    }

    const handleFlashcardClick = () => {
        if (!showNext && index < images.length - 1 ) {
            setShowNext(true);
        }
    }


    return <div className="course-page">
            <Menu />
            {images && images[index] && 
            <Flashcard artwork={images[index]} onClick={handleFlashcardClick} showNext={showNext}/> } 
            { showNext && <div className="next-flashcard-button" onClick={handleClick}>
                <ImArrowRight size={window.innerWidth < 600? 25: 50}/>
                <p>NEXT</p>
            </div> }
            <div className="timer-container">{index + 1}/{images.length}</div>
        </div>

}

export default FlashcardPage;