import axios from "axios";
import { useEffect, useState } from "react";
import Menu from "../components/Menu";
import ArtworksCatalogCard from "../components/ArtworksCatalogCard";
import Tag from "../components/Tag";
import { decypherToken } from "../utils";
import { api_url } from "../constants";

function ArtworksCatalogPage () {

    const [images, setImages] = useState([]); 
    const [course, setCourse] = useState({}); 
    const [tags, setTags] = useState([]);
    const [search, setSearch] = useState("");
    const [periods, setPeriods] = useState([]);
    const [types, setTypes] = useState([]);

    const getSections = async (courseSlug) => {
        
        const options = await decypherToken();
        const data = {
            slug: courseSlug
        }

        const response = await axios.post(api_url + `artworks/studied`, data, options);
        setImages(response.data.artworks);
        setCourse(response.data.course);

        let awPeriods = [];
        let awTypes = [];
        let periodIds = [];
        let typeIds = [];

        response.data.tags.map((tag) => {
            if (tag.tag_type == "period") {
                periodIds.push(tag.id);
                awPeriods.push({id: tag.id, label: tag.tag});
            } else if (tag.tag_type == "type"){
                typeIds.push(tag.id);
                awTypes.push({id: tag.id, label: tag.tag})
            }
        });
       
        setPeriods(awPeriods);
        setTypes(awTypes);
    }

    const reload = async (tags, search) => {

        const options = await decypherToken();
        const data = {
            course: course.id,
            tags: tags,
            search: search
        }
    
        const response = await axios.post(api_url + `artworks/studied`, data, options);                            
        setImages(response.data.artworks);
    }

    useEffect( () => {
        let slug = sessionStorage.getItem("slug");
        getSections(slug);
    }, []);

    const handleTagChange = (tagId, selected) => {

        let newTags = tags;
        
        if (newTags.includes(tagId)){
            newTags.splice(newTags.indexOf(tagId), 1);
        } else if (!newTags.includes(tagId)){
            newTags.push(tagId);
        }

       setTags(newTags);
       reload(newTags, search);


    }

    const renderImages = images.map((image) => {

        image.image_url = 
            image.image_url.includes("http")? 
            image.image_url:
            api_url + "assets/" + image.image_url;

        return <ArtworksCatalogCard image={ image }  key={image.id} course={course.id}/>
    });

    const renderPeriods = periods.map((period) => {
        return <Tag key={period.id} label={period.label} id={period.id} onChange={handleTagChange}/>;
    });

    const renderTypes = types.map((type) => {
        return <Tag key={type.id} label={type.label} id={type.id} onChange={handleTagChange}/>;
    });

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        reload(tags, e.target.value);
    }


    return <div className="artwork-page">
            <Menu />
            <h3>{course.title}</h3>
            <div style={{display: "flex"}}>

                <div className="catalog-inputs">
                    <div >
                        <label htmlFor="artwork-period">Period</label>
                        <div name="artwork-period" id="artwork-period" >
                            {renderPeriods}
                        </div>
                    </div>

                    <div >
                        <label htmlFor="artwork-type">Type</label>
                        <div name="artwork-type" id="artwork-type" >
                            {renderTypes}
                        </div>
                    </div>

                    <div >
                        <label htmlFor="search-artwork">Search artwork</label>
                        <input type="text" onChange={handleSearchChange} placeholder="Search by title or author"></input>
                    </div>
                </div>

                <div className="list">
                    { renderImages }
                </div> 
            
            </div>
        
            </div>

}

export default ArtworksCatalogPage;