import "../index.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { GiTeacher } from  "react-icons/gi";
import { decypherToken } from "../utils";
import { api_url, backend_url, home_url } from "../constants";
import { MdManageAccounts } from "react-icons/md";


function GeneralMenu() {

    let [ name, setName ] = useState(""); 
    let [ instructor, setInstructor ] = useState(false);
    let [ showUserArea, setShowUserArea ] = useState(Boolean(localStorage.IYFLClxBz4IE3rn6OwU0tef1fm4YVcBS) && window.innerWidth > 600);

    useEffect(() => {
        if (localStorage.IYFLClxBz4IE3rn6OwU0tef1fm4YVcBS) {
            getUserData();
        }    
    }, []);

    const getUserData = async () => {

        let options = null;

        try {
            options = await decypherToken();
        } catch (err) {
            localStorage.clear();
            window.location.href = window.location.origin;
        }
       
        const response = await axios.get(api_url + `user`, options);

        if (!response.data.length) {
            localStorage.clear();
            window.location.hreff = window.location.origin;
        } else {
            response.data[0].name?
            setName(response.data[0].name):
            setName(response.data[0].email);
            setInstructor(response.data[0].instructor === 1);
        }
        
    }
    

    const handleClick = (event) => {
        localStorage.clear();
       window.location.href = window.location.origin;
    }

    const handeInstructorClick = async (event) => {

        const options = await decypherToken();

        const response = await axios.get(api_url + `user`,
        options);

        if (!response.data.length) {
            localStorage.clear();
            window.location.href = window.location.origin;
        } else {
            window.location.href = backend_url + "?test=" + localStorage.IYFLClxBz4IE3rn6OwU0tef1fm4YVcBS;
        }
    } 

    const toggleMenu = (event) => {

        event.preventDefault();
        if (Boolean(localStorage.IYFLClxBz4IE3rn6OwU0tef1fm4YVcBS)) {
            setShowUserArea(!showUserArea);    
        }

    }

    const handleResize = () => {

        if (Boolean(localStorage.IYFLClxBz4IE3rn6OwU0tef1fm4YVcBS)) {
            if (window.innerWidth > 600 ) {
                setShowUserArea(true);
            } else {
                setShowUserArea(false);
            }
        } else {
            setShowUserArea(false);  
        }
        
    }
    
    window.addEventListener("resize", handleResize);

    return <div className="general-menu-container">
                <h2><a href={home_url}>Art history courses</a></h2>
                { Boolean(localStorage.IYFLClxBz4IE3rn6OwU0tef1fm4YVcBS) &&
                    <h2 className="general-menu-mobile" onClick={toggleMenu}>
                    <IconContext.Provider value={{ color: "black" }} >
                        <div>
                            <MdManageAccounts size={30}/>
                        </div>
                    </IconContext.Provider>
                </h2> }
                { showUserArea  && <div className="user-area">
                    <h3 >{name}</h3>
                    {instructor && <h3 onClick={handeInstructorClick} style={{ cursor: "pointer"}}>
                        <IconContext.Provider value={{ color: "black" }} >
                            <div>
                                <GiTeacher size={40}/> 
                            </div>
                        </IconContext.Provider>
                    </h3> }
                    <h3 onClick={handleClick} style={{cursor: "pointer"}}>
                        <IconContext.Provider value={{ color: "black" }} >
                            <div>
                                <FaArrowRightToBracket size={40}/> 
                            </div>
                        </IconContext.Provider>
                    </h3>
                </div>}
                
            </div>

}

export default GeneralMenu;