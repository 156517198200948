import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {FaTimes} from "react-icons/fa";

function Modal( {onClose, course} ){

    const [courseDescription, setCourseDescription] = useState("");
    const [courseTitle, setCourseTitle] = useState("");

    useEffect(() => {

        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        }
    }, []);

   
    useEffect(() => {

        setCourseDescription(course.description);
        setCourseTitle(course.title);
     
    }, [course.description]);

    return ReactDOM.createPortal(
    <div>
        <div onClick={onClose} className="modal-bg"></div>
        <div className="modal">
            <div className="close-modal" onClick={onClose}>
                <FaTimes />
            </div>
            <h4>{courseTitle}</h4>
            <p>{courseDescription}</p>
        </div>
    </div>,
    document.querySelector(".modal-container"));
} 

export default Modal;