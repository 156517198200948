import { useState, useEffect } from "react";
import axios from "axios";
import "../index.css";
import ArtworkExplanation from "../components/ArtworkExplanations";
import ArtworkAppreciation from "../components/ArtworkAppreciation";
import Menu from "../components/Menu";
import { decypherToken } from "../utils";
import { api_url } from "../constants";

function ArtworkPage() {

  
    let params = new URL(document.location).searchParams;
    const courseId =  params.get("course");
    const awId = params.get("id");

    const [ awData, setAwData ] = useState({});
    const [ awExtraImages, setAwExtraImages ] = useState([]);
    const [ nextAw, setNextAw ] = useState();
    const [ tags, setTags ] = useState([]);

    const fetchAwData = async () => {
        
        const options = await decypherToken();
        
        const url = api_url + `artwork?id=${awId}&course=${courseId}`; 
        const response = await axios.get(url, options);
        setAwData(response.data.artworkInfo);
        setAwExtraImages(response.data.extraImages);
        setTags(response.data.tags);
        response.data.nextArtwork?
            setNextAw(response.data.nextArtwork.aw_id):
            setNextAw(null);
    }

    //devo prendere i dati e decidere se e gia stato completed o no?
    //it makes sense actually....

    useEffect(() => {
        fetchAwData();
    }, []);

    return <div className="artwork-page">
                <Menu />
                {!Boolean(awData.completed) && 
                    <ArtworkAppreciation awData={awData} courseId={courseId} awExtraImages={awExtraImages}/>
                }
                { Boolean(awData.completed) && 
                    <div >
                        <h1 className="page-title">{awData.title}</h1>
                       <ArtworkExplanation awData={awData} awExtraImages={awExtraImages} nextAw={nextAw} courseId={courseId} tags={tags}/>
                    </div>
                }
            </div>
       
}

export default ArtworkPage;