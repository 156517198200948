import { useState } from "react";

function TextInput({label, name, password, onChange}) {

    const type= password? "password": "text";

    const [value, setValue] = useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value, name);
    }

    return <div className="text-input">
                <label>{label}</label>
                <input onChange={handleChange} type={type} name={name} value={value}/>
            </div>

}

export default TextInput;