import { useState, useEffect, useRef } from "react";
import VisualAnalysisQuestions from "./VisualAnalysisQuestions";
import axios from "axios";
import {magnify, removeMagnify, decypherToken} from "../utils";
import { iframe_link, assets_url, api_url } from "../constants";
import SkipTimerModal from "./SkipTimerModal";

function ArtworkAppreciation({awData, courseId, awExtraImages}) {

    const [mainImg, setMainImg] = useState(awData.image_url);
    const [extraImages, setExtraImages] = useState(awExtraImages);
    const [showQuestions, setShowQuestions] = useState(false);
    const [timerOver, setTimerOver] = useState(false);
    const [showModal, setShowModal ] = useState(false);
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(false);
    const [magnifyMode, setMagnifyMode] = useState(false);
  
    const myRef = useRef(null);

    useEffect(() => {
        setMainImg(awData.image_url)
    }, [awData.image_url]);

    useEffect(() => {
        setExtraImages(awExtraImages)
    }, [awExtraImages]);

    const handleButtonClick = () => {
        setShowQuestions(!showQuestions);
    } 

    const changeMainImg = (imageUrl) => {

        let newExtraImages = extraImages;

        newExtraImages.map((extraImage, index) => {
            if (extraImage.rel_path == imageUrl) {
                newExtraImages.splice(index, 1);
            }
        })
        
        setExtraImages([...newExtraImages, {rel_path: mainImg.replace(assets_url, "")}]);

        setMainImg(imageUrl);
       
    }
    
    const renderExtraImages = extraImages.map((image)=>{
        return <div className="extra-img" key={image.rel_path} onClick={() => changeMainImg(image.rel_path) } data-code={image.rel_path}>
            <img src={assets_url + image.rel_path}/>
        </div>
    });
  
    useEffect(() => {
        let timerId;

        if (runTimer) {
            setCountDown(60 * 5);
            timerId = setInterval(() => {
              setCountDown((countDown) => countDown - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);

    },[runTimer]);

    const togglerTimer = () => setRunTimer((t) => !t);

    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);
    useEffect(() => {
        togglerTimer();
    }, []);
   

    useEffect(() => {
        if (countDown < 0 && runTimer) {
            setRunTimer(false);
            setCountDown(0);
            setTimerOver(true); 
            saveAwCompletion();
          }
    },[countDown, runTimer]);

    const saveAwCompletion = async () => {

        const options = await decypherToken();
        let data = {
            aw_id: awData.id,
            course_id: courseId
        }

        await axios.post(api_url + "artwork-completion", data, options)

        return true;
    }

    const handleProceedClick = async () => {
        if (timerOver) {
            window.location.reload();
        } else {
            setShowModal(true);
        }
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const handleSkipTimerClick = async () => {
        if (!timerOver) await saveAwCompletion();
        window.location.reload();
    }


    return  <div className="explanation-page-container">
                <div className="main-img-container">            
                    <div className="aw-appreciation-container" >

                        <iframe className="isss_iframe" src={iframe_link + mainImg} allowFullScreen>
                        </iframe>

                        { Boolean(extraImages.length) &&
                        <div className="extra-images-container" >
                            {renderExtraImages}
                        </div>
                        }

                    </div>

                    <div className="caption-container">
                        {awData.author && <p>{awData.author}</p> }
                        {awData.title && <p ><strong>{awData.title}</strong></p> }
                        {awData.date && <p >{awData.date}</p> }
                        {awData.period && <p >{awData.period}</p> }
                        {awData.media && <p >{awData.media}</p> }
                        {awData.size && <p >{awData.size}</p> }
                        {awData.collocation && <p >{awData.collocation}</p> }
                    </div>            
                </div>
                <button className="show-questions-button" onClick={handleButtonClick}>Visual analysis aid</button>
                {
                    showQuestions && 
                    <div className="aw-appreciation-questions">
                        <VisualAnalysisQuestions />
                    </div>
                }
               
                {!timerOver && <div className="timer-container">{minutes}:{seconds}</div> }
                <div className="timer-button-container"><button onClick={handleProceedClick}>Go to artwork explanation</button></div> 
                {showModal && <SkipTimerModal onClose={handleClose} onClick={handleSkipTimerClick}/>}
            </div>

}

export default ArtworkAppreciation;